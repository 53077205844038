import React from "react";
import { Row, Col, Grid } from "react-bootstrap";
import Header from "../header/Header";

import UserTable from "./UserTable";

export default function Users() {
  return (
    <>
      <Row style={{ height: "100vh", width: "100%" }}>
        <Header
          menuActive={{
            dashboard: false,
            users: true,
            services: false,
            payments: false,
          }}
        />
        <Col sm={10} xs={12} lg={10} md={12}>
          <Grid style={{ margin: "5%" }}>
            <h1>Users</h1>
            <UserTable />
          </Grid>
        </Col>
      </Row>
    </>
  );
}
