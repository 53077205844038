import React, { Component } from "react";
import { FormControl, Grid, Row, Col, Label } from "react-bootstrap";
import Header from "../header/Header";
import api from "../../services/Api";

export default class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dashboard: {},
      fecha1: "2020-08-20",
      fecha2: "2021-08-28",
      dashboardByService: {},
      categories: [],
      id_category: 1,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    api.getCategories((res) => {
      this.setState({ categories: res.data });
    });

    api.getDashboardData(this.state.fecha1, this.state.fecha2, (res) => {
      this.setState({ dashboard: res.bidData });
    });
    api.getDashboardDataByStatus(this.state.id_category, (res) => {
      this.setState({ dashboardByService: res.bidData });
    });
  }

  handleChange(event) {
    console.log("hello");
    console.log(event.target.value);
    const { name, value } = event.target;
    this.setState({ [name]: value });
    //console.log("name" + name);

    //if (name !== "category") {
      //console.log("pepe");
      api.getDashboardData(this.state.fecha1, this.state.fecha2, (res) => {
        this.setState({ dashboard: res.bidData });
      });
    //} else {
      //console.log("pecas");
      api.getDashboardDataByStatus(this.state.id_category, (res) => {
        //console.log(res.bidData)
        this.setState({ dashboardByService: res.bidData });
      });
    //}
  }

  render() {
    return (
      <Row style={{ height: "100vh", width: "100%" }}>
        <Header
          menuActive={{
            dashboard: true,
            users: false,
            services: false,
            payments: false,
          }}
        />

        <Col sm={10} xs={12} lg={10} md={12}>
          <Grid style={{ width: "80%" }}>
            <h1>Dashboard</h1>
            <div style={{ marginTop: "35px" }}></div>
            <Row>
              <Col md={4}>
                <Label>Since</Label>
                <FormControl
                  type="date"
                  value={this.state.fecha1}
                  name="fecha1"
                  onChange={this.handleChange}
                  style={{ borderRadius: "12px" }}
                  required
                />
              </Col>
              <Col md={4}>
                <Label>To</Label>
                <FormControl
                  type="date"
                  value={this.state.fecha2}
                  name="fecha2"
                  onChange={this.handleChange}
                  style={{ borderRadius: "12px" }}
                  required
                />
              </Col>
            </Row>
            <div style={{ marginTop: "35px" }}></div>
            <Row>
              <Col md={3} style={{ textAlign: "center" }}>
                <Label style={{ fontSize: "25px", fontFamily: "Verdana" }}>
                  Actual Bids
                </Label>
                <br></br>
                <Label
                  style={{
                    fontSize: "55px",
                    fontFamily: "Verdana",
                    color: "#33C7FF",
                  }}
                >
                  {this.state.dashboard.actualBids}
                </Label>
                <br></br>
                <Label style={{ fontSize: "25px", fontFamily: "Calibri" }}>
                  Bids
                </Label>
              </Col>
              <Col md={3} style={{ textAlign: "center" }}>
                <Label style={{ fontSize: "20px", fontFamily: "Verdana" }}>
                  Transactions made
                </Label>
                <br></br>
                <Label
                  style={{
                    fontSize: "55px",
                    fontFamily: "Verdana",
                    color: "#33C7FF",
                  }}
                >
                  {this.state.dashboard.transactionMade}
                </Label>
                <br></br>
                <Label style={{ fontSize: "25px", fontFamily: "Calibri" }}>
                  Transactions
                </Label>
              </Col>
              <Col md={3} style={{ textAlign: "center" }}>
                <Label style={{ fontSize: "25px", fontFamily: "Verdana" }}>
                  Total earned
                </Label>
                <br></br>
                <Label
                  style={{
                    fontSize: "55px",
                    fontFamily: "Verdana",
                    color: "#33C7FF",
                  }}
                >
                  ${this.state.dashboard.totalEarned || 0}
                </Label>
                <br></br>
                <Label style={{ fontSize: "25px", fontFamily: "Calibri" }}>
                  CAD
                </Label>
              </Col>
              <Col md={3} style={{ textAlign: "center" }}>
                <Label style={{ fontSize: "25px", fontFamily: "Verdana" }}>
                  Bids done
                </Label>
                <br></br>
                <Label
                  style={{
                    fontSize: "55px",
                    fontFamily: "Verdana",
                    color: "#33C7FF",
                  }}
                >
                  {this.state.dashboard.bidsDone}
                </Label>
                <br></br>
                <Label style={{ fontSize: "25px", fontFamily: "Calibri" }}>
                  Bids
                </Label>
              </Col>
            </Row>
            <hr style={{ borderTop: "4px solid ", marginTop: "5%" }}></hr>
            <h1>By Service</h1>
            <div style={{ marginTop: "35px" }}></div>
            <Row>
              <Col md={1}>
                {" "}
                <Label>Service: </Label>
              </Col>

              <Col md={3}>
                <select
                  className="browser-default custom-select"
                  name="id_category"
                  value={this.state.id_category}
                  onChange={this.handleChange}
                >
                  {this.state.categories.map((category) => (
                    <option
                      key={category.id_category}
                      value={category.id_category}
                    >
                      {category.category}
                    </option>
                  ))}
                </select>
              </Col>
            </Row>
            <div style={{ marginTop: "35px" }}></div>
            <Row>
              <Col md={3} style={{ textAlign: "center" }}>
                <Label style={{ fontSize: "25px", fontFamily: "Verdana" }}>
                  Actual Bids
                </Label>
                <br></br>
                <Label
                  style={{
                    fontSize: "55px",
                    fontFamily: "Verdana",
                    color: "#33C7FF",
                  }}
                >
                  {this.state.dashboardByService.actualBids}
                </Label>
                <br></br>
                <Label style={{ fontSize: "25px", fontFamily: "Calibri" }}>
                  Bids
                </Label>
              </Col>
              <Col md={3} style={{ textAlign: "center" }}>
                <Label style={{ fontSize: "20px", fontFamily: "Verdana" }}>
                  Transactions made
                </Label>
                <br></br>
                <Label
                  style={{
                    fontSize: "55px",
                    fontFamily: "Verdana",
                    color: "#33C7FF",
                  }}
                >
                  {this.state.dashboardByService.transactionMade}
                </Label>
                <br></br>
                <Label style={{ fontSize: "25px", fontFamily: "Calibri" }}>
                  Transactions
                </Label>
              </Col>
              <Col md={3} style={{ textAlign: "center" }}>
                <Label style={{ fontSize: "25px", fontFamily: "Verdana" }}>
                  Total earned
                </Label>
                <br></br>
                <Label
                  style={{
                    fontSize: "55px",
                    fontFamily: "Verdana",
                    color: "#33C7FF",
                  }}
                >
                  ${this.state.dashboardByService.totalEarned || 0}
                </Label>
                <br></br>
                <Label style={{ fontSize: "25px", fontFamily: "Calibri" }}>
                  CAD
                </Label>
              </Col>
              <Col md={3} style={{ textAlign: "center" }}>
                <Label style={{ fontSize: "25px", fontFamily: "Verdana" }}>
                  Bids done
                </Label>
                <br></br>
                <Label
                  style={{
                    fontSize: "55px",
                    fontFamily: "Verdana",
                    color: "#33C7FF",
                  }}
                >
                  {this.state.dashboardByService.bidsDone}
                </Label>
                <br></br>
                <Label style={{ fontSize: "25px", fontFamily: "Calibri" }}>
                  Bids
                </Label>
              </Col>
            </Row>
          </Grid>
        </Col>
      </Row>
    );
  }
}
