/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import {
  Form,
  Button,
  FormGroup,
  FormControl,
  Row,
  Label,
} from "react-bootstrap";
import api from "../../services/Api";
import { Link } from "react-router-dom";
export default class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = { email: "", password: "", isError: false, message: "" };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

  onFormSubmitLogin = (e) => {
    const { email } = this.state;
    e.preventDefault();
    api.recoverPassword(email, (data) => {
      console.log(data);
      if (data.status !== "error") {
        this.setState({ isError: true });
        this.setState({ message: data.message });
      } else {
        this.setState({ isError: true });
        this.setState({ message: data.message });
      }
    });
  };

  render() {
    return (
      <>
        <div style={{ marginTop: "10%" }}></div>

        <div className="justify-content-md-center" style={{ marginLeft:"15px" }}>
          <h2>Forgot Password</h2>
        </div>
        <div style={{ marginTop: "2%" }}></div>
        <div className="justify-content-md-center">
          <Form onSubmit={this.onFormSubmitLogin} style={{ maxWidth: "40%", marginLeft:"15px" }}>
            <FormGroup controlId="formBasicEmail">
              <FormControl
                type="email"
                value={this.state.email}
                name="email"
                onChange={this.handleChange}
                placeholder="Enter email"
                style={{ borderRadius: "12px" }}
                required
              />
            </FormGroup>

            {
              /*
              <FormGroup controlId="formBasicPassword">
                     <FormControl
                        type="password"
                        value={this.state.password}
                        name="password"
                        onChange={this.handleChange}
                        placeholder="Password"
                        style={{borderRadius: "12px"}}
                        required
                     />
              </FormGroup>
              */
            }

            <Button
              variant="primary"
              style={{
                borderRadius: "12px",
                backgroundColor: "#0062cc",
                width: "100%",
                color: "white",
              }}
              type="submit"
            >
              Submit
            </Button>
            {this.state.isError ? <Label>{this.state.message}</Label> : ""}
            <div style={{ marginTop: 20 }}></div>
            <Label>
              <Link to="/">
                <a href="#">log in</a>
              </Link>{" "}
            </Label>
          </Form>
        </div>
      </>
    );
  }
}
