import React, {Component} from "react";
import DataTable from "react-data-table-component";
import {Link} from "react-router-dom";
import {Button} from "react-bootstrap";
import api from "../../services/Api";

const columns = [
   {name: "Name", selector: "first_name", ortable: true},
   {name: "Email", selector: "email", ortable: true},
   {
      selector: "register_type",
      name: "Register type",
      sortable: true,
   },
   {
      selector: "role",
      name: "Rol",
      sortable: true,
   },
   {
      selector: "create_date",
      name: "Register date",
      sortable: true,
   },
   {
      name: "actions",
      cell: (row) => (
         <Link to={"/users/" + row.id_user}>
            <Button style={{borderRadius: "12px", backgroundColor: "#0062cc", width: "100%", color: "white"}}>Details</Button>
         </Link>
      ),
   },
];

export default class UserTable extends Component {
   constructor(props) {
      super(props);
      this.state = {users: []};
   }
   componentDidMount() {
      api.getUsers((res) => {
         this.setState({users: res.data});
      });
   }

   render() {
      return <DataTable columns={columns} data={this.state.users} pagination={true} />;
   }
}
