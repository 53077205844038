import React, { Component } from "react";
//import React, { useState, useEffect } from "react";
import {Row,Col,Grid,Label,Image,Form,FormControl,FormGroup,Button} from "react-bootstrap";
import Header from "../header/Header";
import api from "../../services/Api";

export default class Notifications extends Component {

    constructor(props) {
        super(props);
        this.state = {
            notifications:{
                notifications_title: "",
                notifications_message: ""
            }
        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {

        const { name, value } = event.target;
        let notifications = {
            notifications:{
                notifications_title: this.state.notifications.notifications_title,
                notifications_message: this.state.notifications.notifications_message
            }
        };
        notifications.notifications[name]= value;
        this.setState(notifications);
        console.log(notifications);
    }

    sendNotification(){
        
    }

    render() {

        return (

            <Row style={{ height: "100vh", width: "100%" }}>
                <Header
                menuActive={{
                    dashboard: false,
                    services: false,
                    payments: false,
                    notifications:true
                }}
                />
                <Col sm={10} xs={12} lg={10} md={12}>
                    <Grid style={{ width: "80%" }}>
                        <h1>Notifications</h1>
                        <div style={{ marginTop: "35px" }}></div>
                        <Col md={4}>
                            <Label>Message Title</Label>
                            <FormControl
                            type="text"
                            value={this.state.notifications.notifications_title}
                            name="notifications_title"
                            onChange={this.handleChange}
                            style={{ borderRadius: "12px" }}
                            required
                            />
                        </Col>
                        <Col md={4} style={{marginTop: "20px"}}>
                            <Label>Message Body</Label>
                            <FormControl
                            type="text"
                            value={this.state.notifications.notifications_message}
                            name="notifications_message"
                            onChange={this.handleChange}
                            style={{ borderRadius: "12px" }}
                            required
                            />
                        </Col>
                        <Col md={4}>
                            <Button onClick={this.sendNotification} style={{borderRadius: "12px", backgroundColor: "#0062cc", width: "140px", color: "white", marginTop: "20px"}} >Send</Button>                        
                        </Col>
                    </Grid>            
                </Col>
            </Row>

        );
    }
}    
