/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import {
  Form,
  Button,
  FormGroup,
  FormControl,
  Image,
  Label,
} from "react-bootstrap";
import api from "../../services/Api";
import { Link } from "react-router-dom";
import styled from "styled-components";

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = { email: "", password: "", isError: false, message: "" };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

  onFormSubmitLogin = (e) => {
    const { email, password } = this.state;
    e.preventDefault();
    api.login(email, password, (data) => {
      console.log(data);
      if (data.status !== "error") {
        if (data.userData.type === 1) this.props.history.push("/dashboard");
        else this.props.history.push("/profile");
      } else {
        this.setState({ isError: true });
        this.setState({ message: data.message });
      }
    });
  };

  render() {
    return (
      <Screeen>
        <Image
          src={require("../../assets/img/logo_jobid.png")}
          //  style={{ width: "10%", height: "10%" }}
          //  minWdth="150px"
          rounded
          className="img"
        />

        <FormRow className="justify-content-md-center">
          <Form onSubmit={this.onFormSubmitLogin} className="form">
            <FormGroup controlId="formBasicEmail">
              <FormControl
                type="email"
                value={this.state.email}
                name="email"
                onChange={this.handleChange}
                placeholder="Enter email"
                style={{ borderRadius: "12px" }}
                required
              />
            </FormGroup>

            <FormGroup controlId="formBasicPassword">
              <FormControl
                type="password"
                value={this.state.password}
                name="password"
                onChange={this.handleChange}
                placeholder="Password"
                style={{ borderRadius: "12px" }}
                required
              />
            </FormGroup>

            <Button
              variant="primary"
              style={{
                borderRadius: "12px",
                backgroundColor: "#0062cc",
                width: "100%",
                color: "white",
              }}
              type="submit"
            >
              Submit
            </Button>
            {this.state.isError ? <Label>{this.state.message}</Label> : ""}
            <div style={{ marginTop: 20 }}></div>
            <Label>
              Don't remember your password,{" "}
              <Link to="/forgotpassword">
                <a href="#">click here</a>
              </Link>{" "}
            </Label>
          </Form>
        </FormRow>
      </Screeen>
    );
  }
}

const Screeen = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;

  & .img {
    width: 10%;
    min-width: 150px;
    max-width: 220px;
  }
`;

const FormRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;
  margin-top: 20px;

  & .form {
    width: 20%;
    max-width: 300px;
    min-width: 220px;
  }
`;
