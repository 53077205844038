import React, { useState } from "react";
import { Image, Button, Col } from "react-bootstrap";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarContent,
  SidebarFooter,
} from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import {
  AiOutlineDashboard,
  AiOutlineUser,
  AiOutlineReconciliation,
  AiFillCreditCard,
  AiOutlineLogout,
  AiFillAlert,
} from "react-icons/ai";
import { TiThMenu } from "react-icons/ti";
import { Link } from "react-router-dom";
import api from "../../services/Api";
import styled from "styled-components";

export default function Header(props) {
  const [isToggled, setIsToggled] = useState(false);
  const onFormSubmitLogout = () => api.logout();

  return (
    <Col xs={12} sm={2} lg={2}>
      <ProSidebar
        toggled={isToggled}
        onToggle={setIsToggled}
        // expand="lg"
        breakPoint="lg"
        style={{ backgroundColor: "#231d11" }}
      >
        <SidebarHeader style={{ textAlign: "center" }}>
          <div style={{ marginTop: "10%" }}></div>
          <Image
            src={require("../../assets/img/logo_jobid.png")}
            style={{ width: "40%" }}
            rounded
          />
          <div style={{ marginTop: "10%" }}></div>
        </SidebarHeader>

        <SidebarContent>
          <Menu iconShape="square" style={{ margin: "20px" }}>
            <MenuItem active={props.menuActive.dashboard}>
              <AiOutlineDashboard /> Dashboard <Link to="/dashboard" />
            </MenuItem>
            <MenuItem active={props.menuActive.users}>
              {" "}
              <AiOutlineUser /> Users <Link to="/users" />
            </MenuItem>
            <MenuItem active={props.menuActive.services}>
              <AiOutlineReconciliation /> Services <Link to="/services" />
            </MenuItem>
            <MenuItem active={props.menuActive.payments}>
              {" "}
              <AiFillCreditCard /> Payments <Link to="/payments" />
            </MenuItem>
            <MenuItem active={props.menuActive.payments}>
              {" "}
              <AiFillAlert /> Reports <Link to="/reports" />
           </MenuItem>
            <MenuItem active={props.menuActive.notifications}>
              {" "}
              <AiFillAlert /> Notifications <Link to="/notifications" />
           </MenuItem>           
          </Menu>
        </SidebarContent>
       <SidebarFooter>
          <Menu>
            <MenuItem onClick={onFormSubmitLogout}>
              <AiOutlineLogout /> Log Out <Link to="/" />
            </MenuItem>
          </Menu>
        </SidebarFooter>
      </ProSidebar>

      <BurguerButton
        variant="dark"
        onClick={() => {
          setIsToggled(true);
        }}
      >
        <TiThMenu />
      </BurguerButton>
    </Col>
  );
}

const BurguerButton = styled(Button)`
  display: none;

  @media (max-width: 992px) {
    display: block;
  }
`;
