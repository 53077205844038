import React from "react";

import "./App.css";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Login from "./components/login/Login";
import ForgotPassword from "./components/forgotPassword/ForgotPassword";
import Recover from "./components/recover/Recover";
import Confirm from "./components/confirm/Confirm";
import Dashboard from "./components/dashboard/Dashboard";
import Users from "./components/users/Users";
import UserAuthorized from "./components/userAuthorized/UserAuthorized";
import DetailsProfile from "./components/detailsProfile/DetailsProfile";
import UserDetails from "./components/userDetails/UserDetails";
import Services from "./components/services/Services";
import { ProtectedRoute, ProtectedRouteNoAdmin } from "./protected.route";
import axios from "axios";
import Payments from "./components/payments/Payments";
import JobDone from "./components/jobDone/JobDone";
import ServicesDetails from "./components/servicesDetails/ServicesDetails";
import Profile from "./components/profile/Profile";
import Reports from "./components/reports/Reports";
import Notifications from "./components/notifications/Notifications";
axios.defaults.baseURL = "http://jobid.trebolbit.com/api/";
//axios.defaults.baseURL = "http://localhost:3003/api/";
axios.defaults.headers.post["Content-Type"] = "application/json";

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/forgotpassword" component={ForgotPassword} />
        <Route exact path="/recover" component={Recover} />
        <Route exact path="/confirm" component={Confirm} />
        <ProtectedRoute exact path="/dashboard" component={Dashboard} />
        <ProtectedRoute exact path="/users" component={Users} />
        <ProtectedRoute exact path="/users/:id" component={UserDetails} />
        <ProtectedRoute exact path="/services" component={Services} />
        <ProtectedRoute exact path="/authorizations" component={UserAuthorized} />
        
        <ProtectedRoute exact path="/detailsprofile/:id" component={DetailsProfile} />
        <ProtectedRoute
          exact
          path="/services/:id"
          component={ServicesDetails}
        />
        <ProtectedRoute exact path="/payments" component={Payments} />
        <ProtectedRoute exact path="/payments/:id" component={JobDone} />
        {/* REPORTS */}
        <ProtectedRoute exact path="/reports" component={Reports} />
        <ProtectedRoute exact path="/notifications" component={Notifications} />
        <ProtectedRouteNoAdmin exact path="/profile" component={Profile} />
        <Route path="*" component={() => "404 NOT FOUND"} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
