import React, {useState, useEffect} from "react";
import DataTable from "react-data-table-component";
import {Link} from "react-router-dom";
import {Button} from "react-bootstrap";
import api from "../../services/Api";
import swal from "sweetalert";


export default function UserTable() {

    const columns = [
        {name: "Name", selector: "first_name", ortable: true},
        {name: "Email", selector: "email", ortable: true},
        {
           selector: "create_date",
           name: "Register date",
           sortable: true,
        },{
            name: "actions",
            cell: (row) => (
               <Link to={"/detailsprofile/" + row.id_user}>
                  <Button style={{borderRadius: "12px", backgroundColor: "#0062cc", width: "100%", color: "white"}}>Details</Button>
               </Link>
            ),
         },
        {
           name: "Authorize",
           cell: (row) => (
              
                 <Button onClick={()=>authorize(row.id_user)} style={{borderRadius: "12px", backgroundColor: "#0062cc", width: "60%", color: "white"}}>Approve</Button>
              
           ),
        }
        
     ];
     
     
     const authorize = (id_user)=>{
     
         swal({
             title: "Do you wish to authorize ?",
             text: "please make sure that you whant authorize this user!",
             icon: "warning",
             buttons: true,
             dangerMode: true,
          }).then((willDelete) => {
             if (willDelete) {
                //props.match.params.id
                api.authorize(id_user,(res) => {
                    api.getUsersAuthorized((res) => {
                        setUser(res.data);
                        swal(res.message, {
                            icon: "success",
                         });
                     });
                   
                   
                });
             }
          });
         
     }
    const [user, setUser] = useState({});
    useEffect(() => {
      api.getUsersAuthorized((res) => {
         setUser(res.data);
      });
   },[])

      return <DataTable columns={columns} data={user} pagination={true} />;
   
}
