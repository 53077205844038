import React from "react";
import { Row, Col, Grid } from "react-bootstrap";
import Header from "../header/Header";

import ServicesTable from "./ServicesTable";

export default function Services() {
  return (
    <Row style={{ height: "100vh", width: "100%" }}>
      <Header
        menuActive={{
          dashboard: true,
          users: false,
          services: true,
          payments: false,
        }}
      />
      <Col sm={10} xs={12} lg={10} md={12}>
        <Grid style={{ margin: "5%" }}>
          <h1>Services</h1>
          <ServicesTable />
        </Grid>
      </Col>
    </Row>
  );
}
