import React, { useState, useEffect } from "react";
import { Row, Col, Grid, Image, Button } from "react-bootstrap";
import Header from "../header/Header";
import api from "../../services/Api";
import swal from "sweetalert";

export default function DetailsProfile(props) {
  //   const [user, setUser] = useState({});
  //   const [roles, setRoles] = useState({});
  //   const [newPhoto, setNewPhoto] = useState([]);
  const [profile, setProfile] = useState({});
  const [getPaid, setGetPaid] = useState({});
  const [verification, setVerificationd] = useState({});
  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);
  const [countries, setCountries] = useState([]);
  //   const [birdsData, setBirdsData] = useState({});

  useEffect(() => {
    api.getProfileSection2(props.match.params.id, (res) => {
      if (res.data) setProfile(res.data);
    });
    api.getPaidSection2(props.match.params.id, (res) => {
      if (res.data) setGetPaid(res.data);
    });
    api.getVerification2(props.match.params.id, (res) => {
      if (res.data) setVerificationd(res.data);
    });

    api.getCity((res) => {
      console.log(res.data);
      setCities(res.data);
    });
    api.getStates((res) => {
      console.log(res.data);
      setStates(res.data);
    });
    api.getCountry((res) => {
      console.log(res.data);
      setCountries(res.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //   const getUser = () => {
  //     api.getUserById(props.match.params.id, (res) => {
  //       setUser(res.data[0]);
  //     });
  //   };
  //   const getUserBids = () => {
  //     api.userBidsConsult(props.match.params.id, (res) => {
  //       setBirdsData(res.UserBidsData);
  //     });
  //   };

  //   const getRoles = () => {
  //     api.getRoles((res) => {
  //       setRoles(res.data);
  //     });
  //   };

  //   const handleInputChange = (event) => {
  //     setUser({
  //       ...user,
  //       [event.target.name]: event.target.value,
  //     });
  //   };
  //   const handleInputChangeImage = (event) => {
  //     event.preventDefault();
  //     setNewPhoto([...newPhoto, event.target.files[0]]);
  //   };

  //   const onFormSubmitUpdateImage = (event) => {
  //     event.preventDefault();
  //     if (newPhoto.length > 0) {
  //       let data = new FormData();
  //       data.append("profile_photo", newPhoto[0]);
  //       api.updatePhoto(data, props.match.params.id, (res) => {
  //         getUser();
  //         swal("Good Job", "The photo was updated successfully!", "success");
  //       });
  //     } else swal("Important", "Please select a image!", "warning");
  //   };

  //   const onFormSubmitUpdateDataUser = (event) => {
  //     event.preventDefault();
  //     api.updateUserAdministrator(user, (res) => {
  //       getUser();
  //       swal("Good Job", "The photo was updated successfully!", "success");
  //     });
  //   };

  const authorize = () => {
    swal({
      title: "Do you wish to authorize ?",
      text: "please make sure that you whant authorize this user!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        //props.match.params.id
        api.authorize(props.match.params.id, (res) => {
          swal(res.message, {
            icon: "success",
          });
        });
      }
    });
  };

  return (
    <>
      <Row>
        <Header
          menuActive={{
            dashboard: false,
            users: true,
            services: false,
            payments: false,
          }}
        />
        <Col sm={10} xs={12} lg={10} md={12}>
          <Grid style={{ margin: "5%" }}>
            <h2>Details</h2>
            <div style={{ marginTop: "35px" }}></div>
            <Row>
              <Col md={6}>
                <Row>
                  <Col md={6}>
                    <h2>Personal Details</h2>
                    <h4>First Name: </h4> <label>{profile.first_name}</label>
                    <h4>Last Name: </h4> <label>{profile.last_name}</label>
                    <h4>Email: </h4> <label>{profile.email}</label>
                    <h4>Phone Numer: </h4> <label>{profile.phone}</label>
                  </Col>
                  <Col md={6}>
                    <h2>Address</h2>
                    <h4>Address: </h4> <label>{profile.address}</label>
                    <h4>Postal Code: </h4> <label>{profile.postal_code}</label>
                    <h4>Email: </h4> <label>{profile.email}</label>
                    <h4>Phone Numer: </h4> <label>{profile.phone}</label>
                    <h4>City:</h4>
                    <select
                      className="browser-default custom-select"
                      name="id_city"
                      disabled
                      value={profile.id_city}
                    >
                      <option value="0">Select an option</option>
                      {cities.map((city) => (
                        <option key={city.id_city} value={city.id_city}>
                          {city.city}
                        </option>
                      ))}
                    </select>
                    <h4>Country:</h4>
                    <select
                      className="browser-default custom-select"
                      name="id_country"
                      disabled
                      value={profile.id_country}
                    >
                      <option value="0">Select an option</option>
                      {countries.map((country) => (
                        <option
                          key={country.id_country}
                          value={country.id_country}
                        >
                          {country.country}
                        </option>
                      ))}
                    </select>
                    <h4>Province:</h4>
                    <select
                      className="browser-default custom-select"
                      name="id_state"
                      value={profile.id_state}
                      disabled
                    >
                      <option value="0">Select an option</option>
                      {states.map((state) => (
                        <option key={state.id_state} value={state.id_state}>
                          {state.state}
                        </option>
                      ))}
                    </select>
                  </Col>
                </Row>
              </Col>
              <Col md={6}>
                <Row>
                  <Col md={6}>
                    <h2>Deposit Details</h2>
                    <h4>Account Number: </h4>{" "}
                    <label>{getPaid.account_number}</label>
                    <h4>Transit number: </h4>{" "}
                    <label>{getPaid.transit_number}</label>
                    <h4>Institution number: </h4>{" "}
                    <label>{getPaid.institution_number}</label>
                    <h4>SIN number: </h4> <label>{getPaid.sin_number}</label>
                    <h4>GST number: </h4> <label>{getPaid.gst_number}</label>
                  </Col>
                  <Col md={6} style={{ textAlign: "center" }}>
                    <h2>Identity Verification</h2>
                    <Image
                      src={
                        "http://jobid.trebolbit.com/" + verification.url_back
                      }
                      style={{ width: "100%" }}
                      rounded
                    />
                    <Image
                      src={
                        "http://jobid.trebolbit.com/" + verification.url_face
                      }
                      style={{ width: "100%" }}
                      rounded
                    />
                    <Image
                      src={
                        "http://jobid.trebolbit.com/" + verification.url_front
                      }
                      style={{ width: "100%" }}
                      rounded
                    />
                  </Col>
                </Row>
                <Row></Row>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Button
                  variant="primary"
                  style={{
                    borderRadius: "12px",
                    backgroundColor: "#0062cc",
                    width: "40%",
                    color: "white",
                  }}
                  type="submit"
                  onClick={() => authorize()}
                >
                  Authorize
                </Button>
              </Col>
            </Row>
          </Grid>
        </Col>
      </Row>
    </>
  );
}
