import React from "react";
import {Row, Col, Grid} from "react-bootstrap";
import Header from "../header/Header";

import UserTable from "./UserTable";

export default function UserAuthorized() {
   return (
      <>
         <Row>
            <Col sm={2}>
               <Header menuActive={{dashboard: false, users: true, services: false, payments: false, authorizations: true}}></Header>
            </Col>
            <Col sm={10}>
               <Grid style={{margin: "5%"}}>
                  <h1>Users</h1>
                  <UserTable />
               </Grid>
            </Col>
         </Row>
      </>
   );
}
