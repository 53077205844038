
class Auth{
    constructor(){
        
        this.authenticated = false;
        this.token = '';
    }

    login(data){
        this.authenticated = true;
        localStorage.setItem('authenticated', true);
        localStorage.setItem('token', data.data);
        localStorage.setItem('type', data.userData.type);
        localStorage.setItem('id_user', data.userData.id_user);
        
        this.token = data.data;
    }
    logout(){
        this.authenticated = false;
        this.token = '';
        localStorage.removeItem('authenticated');
        localStorage.removeItem('token');
        localStorage.removeItem('type');
        localStorage.removeItem('id_user');
        window.location=""

    }

    isAuthenticated(){
        return localStorage.getItem('authenticated') || this.authenticated;
    }

    getIdUser(){
        return localStorage.getItem('id_user');
    }

    getUserType(){
        return localStorage.getItem('type');
    }

    getToken() {
        return localStorage.getItem('token');
    }
}

export default new Auth()