import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Grid,
  Label,
  Image,
  Form,
  FormControl,
  FormGroup,
  Button,
} from "react-bootstrap";
import Header from "../header/Header";
import api from "../../services/Api";
import swal from "sweetalert";
import "./style.css";

export default function UserDetails(props) {
  const [user, setUser] = useState({});
  const [roles, setRoles] = useState({});
  const [newPhoto, setNewPhoto] = useState([]);

  const [birdsData, setBirdsData] = useState({});

  useEffect(() => {
    getUser();
    getUserBids();
    getRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUser = () => {
    api.getUserById(props.match.params.id, (res) => {
      setUser(res.data[0]);
    });
  };
  const getUserBids = () => {
    api.userBidsConsult(props.match.params.id, (res) => {
      setBirdsData(res.UserBidsData);
    });
  };

  const getRoles = () => {
    api.getRoles((res) => {
      setRoles(res.data);
    });
  };

  const handleInputChange = (event) => {
    setUser({
      ...user,
      [event.target.name]: event.target.value,
    });
  };
  const handleInputChangeImage = (event) => {
    event.preventDefault();
    setNewPhoto([...newPhoto, event.target.files[0]]);
  };

  const onFormSubmitUpdateImage = (event) => {
    event.preventDefault();
    if (newPhoto.length > 0) {
      let data = new FormData();
      data.append("profile_photo", newPhoto[0]);
      api.updatePhoto(data, props.match.params.id, (res) => {
        getUser();
        swal("Good Job", "The photo was updated successfully!", "success");
      });
    } else swal("Important", "Please select a image!", "warning");
  };

  const onFormSubmitUpdateDataUser = (event) => {
    event.preventDefault();
    api.updateUserAdministrator(user, (res) => {
      getUser();
      swal("Good Job", "The photo was updated successfully!", "success");
    });
  };

  return (
    <>
      <Row style={{ height: "100vh", width: "100%" }}>
        <Header
          menuActive={{
            dashboard: false,
            users: true,
            services: false,
            payments: false,
          }}
        />
        <Col sm={10} xs={12} lg={10} md={12}>
          <Grid style={{ width: "80%" }}>
            <h2>Details</h2>
            <div style={{ marginTop: "35px" }}></div>
            <Row>
              <Col md={10} lg={6} xs={12}>
                <Row>
                  <Col md={7}>
                    <Form onSubmit={onFormSubmitUpdateImage}>
                      <Image
                        src={"http://jobid.trebolbit.com/" + user.profile_photo}
                        style={{ width: "100%" }}
                        rounded
                      />
                      <div style={{ marginTop: "35px" }}></div>
                      <Row>
                        <Col xs={12} md={12}>
                          <FormGroup>
                            <FormControl
                              name="newPhoto"
                              onChange={handleInputChangeImage}
                              type="file"
                              id="exampleFormControlFile1"
                              label="Example file input"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <div style={{ marginTop: "35px" }}></div>
                      <Row>
                        <Col md={12}>
                          <Button
                            variant="primary"
                            style={{
                              borderRadius: "12px",
                              backgroundColor: "#0062cc",
                              width: "100%",
                              color: "white",
                            }}
                            type="submit"
                          >
                            Edit photo
                          </Button>
                        </Col>
                      </Row>
                      <div style={{ marginTop: "15px" }}></div>
                    </Form>
                  </Col>
                  <Col md={10} xs={12} lg={5} className="styled-form">
                    <Form onSubmit={onFormSubmitUpdateDataUser}>
                      <FormGroup controlId="formBasicEmail">
                        <Row>
                          <h4>First Name</h4>
                        </Row>
                        <Row>
                          <FormControl
                            value={user.first_name}
                            name="first_name"
                            placeholder="First name"
                            style={{ borderRadius: "12px" }}
                            onChange={handleInputChange}
                            required
                          />
                        </Row>
                        <div style={{ marginTop: "20px" }}></div>
                        <Row>
                          <h4>Last Name</h4>
                        </Row>
                        <Row>
                          <FormControl
                            value={user.last_name}
                            name="last_name"
                            placeholder="Last Name"
                            style={{ borderRadius: "12px" }}
                            onChange={handleInputChange}
                            required
                          />
                        </Row>
                        <div style={{ marginTop: "20px" }}></div>
                        <Row>
                          <h4>Mail</h4>
                        </Row>
                        <Row>
                          <FormControl
                            type="email"
                            value={user.email}
                            name="email"
                            placeholder="Enter email"
                            style={{ borderRadius: "12px" }}
                            onChange={handleInputChange}
                            required
                          />
                        </Row>
                        <div style={{ marginTop: "20px" }}></div>
                        <Row>
                          <h4>Register Type</h4>
                        </Row>
                        <Row>
                          <FormControl
                            name="register_type"
                            disabled
                            placeholder="Register Type"
                            style={{ borderRadius: "12px" }}
                            value={user.register_type}
                            onChange={handleInputChange}
                            required
                          />
                        </Row>
                        <div style={{ marginTop: "20px" }}></div>
                        <Row>
                          <h4>Register Date</h4>
                        </Row>
                        <Row>
                          <FormControl
                            name="create_date"
                            placeholder="Register Date"
                            disabled
                            style={{ borderRadius: "12px" }}
                            value={user.create_date}
                            onChange={handleInputChange}
                            required
                          />
                        </Row>
                        <div style={{ marginTop: "20px" }}></div>
                        <Row>
                          <h4>Rol</h4>
                        </Row>
                        <Row>
                          <select
                            className="browser-default custom-select"
                            name="id_role"
                            value={user.id_role}
                            onChange={handleInputChange}
                          >
                            {Object.keys(roles).map((role) => (
                              <option
                                key={roles[role].id_role}
                                value={roles[role].id_role}
                              >
                                {roles[role].role}
                              </option>
                            ))}
                          </select>
                        </Row>
                      </FormGroup>
                      <Button
                        variant="primary"
                        style={{
                          borderRadius: "12px",
                          backgroundColor: "#0062cc",
                          width: "100%",
                          color: "white",
                        }}
                        type="submit"
                      >
                        Edit Info
                      </Button>
                    </Form>
                  </Col>
                </Row>
              </Col>
              <Col md={6}>
                <Row>
                  <Col md={6} style={{ textAlign: "center" }}>
                    <Label style={{ fontSize: "25px", fontFamily: "Verdana" }}>
                      Actual Bids
                    </Label>
                    <br></br>
                    <Label
                      style={{
                        fontSize: "55px",
                        fontFamily: "Verdana",
                        color: "#33C7FF",
                      }}
                    >
                      {birdsData.ActualBids}
                    </Label>
                    <br></br>
                    <Label style={{ fontSize: "25px", fontFamily: "Calibri" }}>
                      Bids
                    </Label>
                  </Col>
                  <Col md={6} style={{ textAlign: "center" }}>
                    <Label style={{ fontSize: "20px", fontFamily: "Verdana" }}>
                      Transactions made
                    </Label>
                    <br></br>
                    <Label
                      style={{
                        fontSize: "55px",
                        fontFamily: "Verdana",
                        color: "#33C7FF",
                      }}
                    >
                      {birdsData.TransactionMade}
                    </Label>
                    <br></br>
                    <Label style={{ fontSize: "25px", fontFamily: "Calibri" }}>
                      Transactions
                    </Label>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} style={{ textAlign: "center" }}>
                    <Label style={{ fontSize: "25px", fontFamily: "Verdana" }}>
                      Total earned
                    </Label>
                    <br></br>
                    <Label
                      style={{
                        fontSize: "55px",
                        fontFamily: "Verdana",
                        color: "#33C7FF",
                      }}
                    >
                      {"$" + birdsData.TotalEarned}
                    </Label>
                    <br></br>
                    <Label style={{ fontSize: "25px", fontFamily: "Calibri" }}>
                      CAD
                    </Label>
                  </Col>
                  <Col md={6} style={{ textAlign: "center" }}>
                    <Label style={{ fontSize: "25px", fontFamily: "Verdana" }}>
                      Bids done
                    </Label>
                    <br></br>
                    <Label
                      style={{
                        fontSize: "55px",
                        fontFamily: "Verdana",
                        color: "#33C7FF",
                      }}
                    >
                      {birdsData.BidsDone}
                    </Label>
                    <br></br>
                    <Label style={{ fontSize: "25px", fontFamily: "Calibri" }}>
                      Bids
                    </Label>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Grid>
        </Col>
      </Row>
    </>
  );
}
