import React, { useState } from "react";
import { Button, Col, Image } from "react-bootstrap";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { AiOutlineDashboard, AiOutlineLogout } from "react-icons/ai";
import { Link } from "react-router-dom";
import api from "../../services/Api";
import { TiThMenu } from "react-icons/ti";
import styled from "styled-components";

export default function HeaderBindder(props) {
  const [isToggled, setIsToggled] = useState(false);
  const onFormSubmitLogout = () => api.logout();

  return (
    <Col xs={12} sm={2} lg={2}>
      <ProSidebar
        toggled={isToggled}
        onToggle={setIsToggled}
        breakPoint="lg"
        style={{ backgroundColor: "#231d11" }}
      >
        <SidebarHeader style={{ textAlign: "center" }}>
          <div style={{ marginTop: "10%" }}></div>
          <Image
            src={require("../../assets/img/logo_jobid.png")}
            style={{ width: "40%" }}
            rounded
          />
          <div style={{ marginTop: "10%" }}></div>
        </SidebarHeader>

        <SidebarContent>
          <Menu iconShape="square" style={{ margin: "20px" }}>
            <MenuItem active>
              <AiOutlineDashboard /> Profile <Link to="/profile" />
            </MenuItem>
          </Menu>
        </SidebarContent>

        <SidebarFooter>
          <Menu>
            <MenuItem onClick={() => onFormSubmitLogout()}>
              <AiOutlineLogout /> Log Out <Link to="/" />
            </MenuItem>
          </Menu>
        </SidebarFooter>
      </ProSidebar>

      <BurguerButton
        variant="dark"
        onClick={() => {
          setIsToggled(true);
        }}
      >
        <TiThMenu />
      </BurguerButton>
    </Col>
  );
}

const BurguerButton = styled(Button)`
  display: none;

  @media (max-width: 992px) {
    display: block;
  }
`;
