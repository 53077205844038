import React, { Component } from "react";
import moment from "moment";
import { Button } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import Api from "../../services/Api";
import swal from "sweetalert";

export default class ReportsTable extends Component {
  constructor(props) {
    super(props);
    this.state = { reports: [] };
  }
  componentDidMount() {
    Api.getAllReports((res) => {
      this.setState({ reports: res.data });
    });
  }

  columns = [
    { name: "#", selector: "id_report", ortable: true },
    { name: "Job name", selector: "tittle", ortable: true },
    {
      name: "id_job",
      selector: "id_job",
      sortable: true,
    },
    {
      selector: "email",
      name: "Reporter name",
      sortable: true,
    },
    {
      selector: "create_date",
      name: "Date",
      cell: (row) => (
        <span>
          {moment(row.create_date.toString()).format("MMM DD h:mm A")}
        </span>
      ),

      // sortable: true,
    },
    {
      name: "Details",
      cell: (row) => (
        <Link to={"/services/" + row.id_job}>
          <Button
            style={{
              borderRadius: "12px",
              backgroundColor: "#0062cc",
              width: "100%",
              color: "white",
            }}
          >
            Job Details
          </Button>
        </Link>
      ),
    },
    {
      name: "",
      cell: (row) => (
        <Button
          onClick={() => {
            this.deleteReport(row.id_job);
          }}
          style={{
            borderRadius: "12px",
            backgroundColor: "white",
            width: "100%",
            color: "gray",
          }}
        >
          Delete report
        </Button>
      ),
    },
  ];

  deleteReport(id_job) {
    swal({
      title: "Are you sure you want to delete the report?",
      text: "The report will not be retrievable",
      icon: "warning",
      buttons: ["Cancel", "Delete report"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        //props.match.params.id
        Api.deleteReport(id_job, (res) => this.responseDelete(res, id_job));
      }
    });
  }

  responseDelete(res, id_job) {
    console.log(res);

    if (res.status === "success") {
      let currentReports = [...this.state.reports];
      currentReports = currentReports.filter((item) => item.id_job !== id_job);
      this.setState({
        reports: currentReports,
      });
    } else {
      swal(
        "Error when deleting the report. If this continues contact support.",
        {
          icon: "error",
          dangerMode: true,
        }
      );
    }
  }

  render() {
    return (
      <DataTable
        columns={this.columns}
        data={this.state.reports}
        pagination={true}
      />
    );
  }
}
