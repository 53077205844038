/* eslint-disable no-useless-constructor */
/* eslint-disable eqeqeq */
import auth from "../helpers/Auth";
import axios from "axios";

//const base_url ="http://localhost:3700/api/"
//const base_url = "http://jobid.trebolbit.com/api/"
class Api {
   constructor() {}

   login(enail, password, fc) {
      axios
         .post("users/login", {
            email: enail,
            password: password,
         })
         .then((res) => {
            const {data} = res;
            if (data.status !== "error") {
               auth.login(data);
               fc(res.data);
            } else {
               fc(res.data);
            }
         });
   }

   recoverPassword(email,fc){
      
      axios
         .post("users/recover_password", {
            email: email
         })
         .then((res) => {
            const {data} = res;
            if (data.status !== "error") {
               fc(res.data);
            } else {
               fc(res.data);
            }
         });
   }


   changePassword(password,token,fc){
      
      axios
         .post("users/updatePassword", {
            password: password,
            token: token
         })
         .then((res) => {
            const {data} = res;
            if (data.status !== "error") {
               fc(res.data);
            } else {
               fc(res.data);
            }
         });
   }

   confirmAcount(token,fc){
      
      axios
         .post("users/confirmUser", {
            token: token
         })
         .then((res) => {
            const {data} = res;
            if (data.status !== "error") {
               fc(res.data);
            } else {
               fc(res.data);
            }
         });
   }

   logout() {
      auth.logout();
   }

   getUsers(fc) {
      axios.post("users/userlist", {token: auth.getToken()}).then((res) => {
         if (res.data.type === 0) {
            auth.logout();
         } else {
            fc(res.data);
         }
      });
   }

   getUsersAuthorized(fc) {
      axios.post("users/userauthorized", {token: auth.getToken()}).then((res) => {
         if (res.data.type === 0) {
            auth.logout();
         } else {
            fc(res.data);
         }
      });
   }


   authorize(id, fc) {
      axios.post("users/authorize", {token: auth.getToken(), id_user: id}).then((res) => {
         if (res.data.type === 0) {
            auth.logout();
         } else {
            fc(res.data);
         }
      });
   }

   getUserById(id, fc) {
      axios.post("users/userconsult", {token: auth.getToken(), id_user: id}).then((res) => {
         if (res.data.type === 0) {
            auth.logout();
         } else {
            fc(res.data);
         }
      });
   }
   userBidsConsult(id, fc) {
      axios.post("users/userBidsConsult", {token: auth.getToken(), id_user: id}).then((res) => {
         if (res.data.type === 0) {
            auth.logout();
         } else {
            fc(res.data);
         }
      });
   }

   getDashboardData(date1, date2, fc) {
      axios.post("job_bid/dashboard", {token: auth.getToken(), fecha1: date1, fecha2: date2}).then((res) => {
         if (res.data.type === 0) {
            auth.logout();
         } else {
            fc(res.data);
         }
      });
   }

   getDashboardDataByStatus(category, fc) {
      axios.post("job_bid/byService", {token: auth.getToken(), categoria: category}).then((res) => {
         if (res.data.type === 0) {
            auth.logout();
         } else {
            fc(res.data);
         }
      });
   }

   getJobsList(category, fc) {
      axios.post("jobs/getServices", {token: auth.getToken(), id_category: category || 0}).then((res) => {
         if (res.data.type === 0) {
            auth.logout();
         } else {
            fc(res.data);
         }
      });
   }

   getPaymentList(fc) {
      axios.post("jobs/getPaymentsJobid", {token: auth.getToken()}).then((res) => {
         if (res.data.type === 0) {
            auth.logout();
         } else {
            fc(res.data);
         }
      });
   }

   getCategories(fc) {
      axios.get("job_bid/getCategories").then((res) => {
         if (res.data.type === 0) {
            auth.logout();
         } else {
            fc(res.data);
         }
      });
   }

   getSubCategories(fc) {
      axios.get("job_bid/getSubCategories").then((res) => {
         if (res.data.type === 0) {
            auth.logout();
         } else {
            fc(res.data);
         }
      });
   }

   getJobDone(id, fc) {
      axios.post("jobs/jobDone", {token: auth.getToken(), id_job: id}).then((res) => {
         if (res.data.type === 0) {
            auth.logout();
         } else {
            fc(res.data);
         }
      });
   }

   getJobConsult(id, fc) {
      axios.post("jobs/jobConsult", {token: auth.getToken(), id_job: id}).then((res) => {
         if (res.data.type === 0) {
            auth.logout();
         } else {
            fc(res.data);
         }
      });
   }

   getPayNow(id, fc) {
      axios.post("jobs/pay_to_worker", {token: auth.getToken(), id_job: id}).then((res) => {
         if (res.data.type === 0) {
            auth.logout();
         } else {
            fc(res.data);
         }
      });
   }

   updatePhoto(data, id, fc) {
      data.append("token", auth.getToken());
      data.append("id_user", id);

      axios.post("users/updateUserProfilePhotoAdministrator", data).then((res) => {
         if (res.data.type === 0) {
            auth.logout();
         } else {
            fc(res.data);
         }
      });
   }

   getRoles(fc) {
      axios.get("users/getRoles").then((res) => {
         if (res.data.type === 0) {
            auth.logout();
         } else {
            fc(res.data);
         }
      });
   }

   updateUserAdministrator(data, fc) {
      data.token = auth.getToken();
      axios.post("users/updateUserAdministrator", data).then((res) => {
         if (res.data.type === 0) {
            auth.logout();
         } else {
            fc(res.data);
         }
      });
   }

   updateJob(data, fc) {
      data.token = auth.getToken();
      axios.post("jobs/update", data).then((res) => {
         if (res.data.type === 0) {
            auth.logout();
         } else {
            fc(res.data);
         }
      });
   }

   deleteJob(id, fc) {
      axios.post("jobs/deleteJob", {token: auth.getToken(), id_job: id}).then((res) => {
         if (res.data.type === 0) {
            auth.logout();
         } else {
            fc(res.data);
         }
      });
   }

   saveProfileSection(profile, fc) {
      profile.id_user = auth.getIdUser();
      profile.token = auth.getToken();
      console.log(profile);
      axios.post("deposit_details/registerAddress", profile).then((res) => {
         if (res.data.type === 0) {
            auth.logout();
         } else {
            fc(res.data);
         }
      });
   }

   saveGetPaidSection(getPaid, fc) {
      getPaid.id_user = auth.getIdUser();
      getPaid.token = auth.getToken();
      axios.post("deposit_details/register", getPaid).then((res) => {
         if (res.data.type === 0) {
            auth.logout();
         } else {
            fc(res.data);
         }
      });
   }

   getProfileSection(fc) {
      axios.post("deposit_details/registerAddress_consult", {token: auth.getToken(), id_user: auth.getIdUser()}).then((res) => {
         if (res.data.type === 0) {
            auth.logout();
         } else {
            fc(res.data);
         }
      });
   }

   getPaidSection(fc) {
      axios.post("deposit_details/consult", {token: auth.getToken(), id_user: auth.getIdUser()}).then((res) => {
         if (res.data.type === 0) {
            auth.logout();
         } else {
            fc(res.data);
         }
      });
   }

   getProfileSection2(id,fc) {
      axios.post("deposit_details/registerAddress_consult", {token: auth.getToken(), id_user: id}).then((res) => {
         if (res.data.type === 0) {
            auth.logout();
         } else {
            fc(res.data);
         }
      });
   }

   getPaidSection2(id,fc) {
      axios.post("deposit_details/consult", {token: auth.getToken(), id_user: id}).then((res) => {
         if (res.data.type === 0) {
            auth.logout();
         } else {
            fc(res.data);
         }
      });
   }

   getVerification2(id,fc) {
      axios.post("deposit_details/verification_consult", {token: auth.getToken(), id_user: id}).then((res) => {
         if (res.data.type === 0) {
            auth.logout();
         } else {
            fc(res.data);
         }
      });
   }

   getCity(fc) {
      axios.get("jobs/city").then((res) => {
         if (res.data.type === 0) {
            auth.logout();
         } else {
            fc(res.data);
         }
      });
   }

   getStates(fc) {
      axios.get("jobs/state").then((res) => {
         if (res.data.type === 0) {
            auth.logout();
         } else {
            fc(res.data);
         }
      });
   }

   getCountry(fc) {
      axios.get("jobs/country").then((res) => {
         if (res.data.type === 0) {
            auth.logout();
         } else {
            fc(res.data);
         }
      });
   }

   updateVerification(data, fc) {
      data.append("token", auth.getToken());
      data.append("id_user", auth.getIdUser());

      axios.post("deposit_details/verification", data).then((res) => {
         if (res.data.type === 0) {
            auth.logout();
         } else {
            fc(res.data);
         }
      });
   }

   getVerification(fc) {
      axios.post("deposit_details/verification_consult", {token: auth.getToken(), id_user: auth.getIdUser()}).then((res) => {
         if (res.data.type === 0) {
            auth.logout();
         } else {
            fc(res.data);
         }
      });
   }


   /* REPORTS */

   getAllReports(fc){
      axios.post("report/list", {token: auth.getToken()}).then((res) => {
         if (res.data.type === 0) {
            auth.logout();
         } else {
            fc(res.data);
         }
      });
   }

   deleteReport(id_job, fc){
      axios.post(`report/delete`, {
         id_job, token: auth.getToken()
      })
      .then((res) => {
         if (res.data.type === 0) {
            auth.logout();
         } else {
            fc(res.data);
         }
      });
   }

}

export default new Api();
