/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import {
  Form,
  Button,
  FormGroup,
  FormControl,
  Row,
  Label,
} from "react-bootstrap";
import api from "../../services/Api";
import { Link } from "react-router-dom";
export default class Recover extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirm_password: "",
      password: "",
      isError: false,
      message: "",
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

  onFormSubmitLogin = (e) => {
    const { password, confirm_password } = this.state;
    e.preventDefault();
    if (password === confirm_password) {
      const query = new URLSearchParams(this.props.location.search);

      const token = query.get("token");

      api.changePassword(password, token, (data) => {
        console.log(data);
        if (data.status !== "error") {
          this.setState({ isError: true });
          this.setState({ message: data.message });
          setTimeout(() => {
            this.props.history.push("/");
          }, 3000);
        } else {
          this.setState({ isError: true });
          this.setState({ message: data.message });
        }
      });
    } else {
      this.setState({ isError: true });
      this.setState({ message: "Passwords do not match" });
    }
  };

  render() {
    return (
      <>
        <div style={{ marginTop: "10%" }}></div>

        <div className="justify-content-md-center" style={{ maxWidth: "50%", marginLeft: "15px" }}>
          <h2>New Password</h2>
        </div>
        <div style={{ marginTop: "2%" }}></div>
        <div className="justify-content-md-center">
          <Form onSubmit={this.onFormSubmitLogin} style={{ maxWidth: "50%", marginLeft: "15px" }}>
            <FormGroup controlId="formBasicPassword">
              <FormControl
                type="password"
                value={this.state.password}
                name="password"
                onChange={this.handleChange}
                placeholder="New Password"
                style={{ borderRadius: "12px" }}
                required
              />
            </FormGroup>

            <FormGroup controlId="formBasicPassword">
              <FormControl
                type="password"
                value={this.state.confirm_password}
                name="confirm_password"
                onChange={this.handleChange}
                placeholder="Confirm Password"
                style={{ borderRadius: "12px" }}
                required
              />
            </FormGroup>

            <Button
              variant="primary"
              style={{
                borderRadius: "12px",
                backgroundColor: "#0062cc",
                width: "100%",
                color: "white",
              }}
              type="submit"
            >
              Submit
            </Button>
            {this.state.isError ? <Label>{this.state.message}</Label> : ""}
            <div style={{ marginTop: 20 }}></div>
            <Label>
              <Link to="/">
                <a href="#">log in</a>
              </Link>{" "}
            </Label>
          </Form>
        </div>
      </>
    );
  }
}
