import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Row, Col, FormControl, FormGroup, Image } from "react-bootstrap";
import HeaderBidder from "../header/HeaderBidder";
import { AiFillCheckCircle } from "react-icons/ai";
import api from "../../services/Api";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

export default function HorizontalLinearStepper() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const [profile, setProfile] = useState({});
  const [getPaid, setGetPaid] = useState({});
  const [verification, setVerificationd] = useState({});
  const steps = getSteps();
  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);
  const [countries, setCountries] = useState([]);
  const [newPhoto1, setNewPhoto1] = useState([]);
  const [newPhoto2, setNewPhoto2] = useState([]);
  const [newPhoto3, setNewPhoto3] = useState([]);
  useEffect(() => {
    //props.match.params.id
    api.getCity((res) => {
      console.log(res.data);
      setCities(res.data);
    });
    api.getStates((res) => {
      console.log(res.data);
      setStates(res.data);
    });
    api.getCountry((res) => {
      console.log(res.data);
      setCountries(res.data);
    });
    api.getProfileSection((res) => {
      if (res.data) setProfile(res.data);
    });
    api.getPaidSection((res) => {
      if (res.data) setGetPaid(res.data);
    });
    api.getVerification((res) => {
      if (res.data) setVerificationd(res.data);
    });
  }, []);

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    let full = false;
    if (activeStep === 0) full = validateSteep0();
    if (activeStep === 1) full = validateSteep1();
    if (activeStep === 2) full = validateSteep2();

    if (activeStep === 0 && full) api.saveProfileSection(profile, () => {});
    if (activeStep === 1 && full) api.saveGetPaidSection(getPaid, () => {});
    if (activeStep === 2 && full) onFormSubmitUpdateImage();

    console.log("STEP 0", validateSteep0);
    console.log("STEP 1", validateSteep1);
    console.log("STEP 2", validateSteep2);

    if (full) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    } else {
      alert("All data is required ");
    }
  };

  const validateSteep0 = () => {
    console.log(profile);

    if (
      profile.first_name !== "" &&
      profile.last_name !== "" &&
      profile.email !== "" &&
      profile.phone !== "" &&
      profile.address !== "" &&
      profile.postal_code !== "" &&
      profile.id_city > 0 &&
      profile.id_state > 0 &&
      profile.id_country > 0
    ) {
      return true;
    } else {
      return false;
    }
  };
  const validateSteep1 = () => {
    if (
      getPaid.account_number !== "" &&
      getPaid.transit_number !== "" &&
      getPaid.sin_number !== "" &&
      getPaid.institution_number !== "" &&
      getPaid.gst_number !== ""
    ) {
      return true;
    } else {
      return false;
    }
  };

  const validateSteep2 = () => {
    // console.log(verification);

    if (verification.type.length > 1) return true;
    return false;
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  function getSteps() {
    return ["Profile", "Get Paid", "Verification", "Complete"];
  }

  function getStepContent(step) {
    switch (step) {
      case 0:
        return profileContent();
      case 1:
        return getPaidContent();
      case 2:
        return verificationContent();
      default:
        return completedContent();
    }
  }

  const handleInputChangeProfile = (event) => {
    setProfile({
      ...profile,
      [event.target.name]: event.target.value,
    });
  };

  const handleInputChangeGetPid = (event) => {
    setGetPaid({
      ...getPaid,
      [event.target.name]: event.target.value,
    });
  };
  const handleInputChangeVerification = (event) => {
    setVerificationd({
      ...verification,
      [event.target.name]: event.target.value,
    });
  };

  function profileContent() {
    return (
      <>
        <Row style={{ height: "100%", padding: "0.5em" }}>
          <Col md={6}>
            <h3>Personal Details</h3>
            <div style={{ marginTop: "25px" }}></div>
            <Row>
              <Col md={4}>
                <label> Given Name*</label>
              </Col>
              <Col md={8}>
                <FormControl
                  name="first_name"
                  value={profile.first_name}
                  placeholder="First name"
                  onChange={handleInputChangeProfile}
                  required
                />
                <label>as it appears on your ID*</label>
              </Col>
            </Row>
            <div style={{ marginTop: "15px" }}></div>
            <Row>
              <Col md={4}>
                <label> Last Name*</label>
              </Col>
              <Col md={8}>
                <FormControl
                  name="last_name"
                  value={profile.last_name}
                  placeholder="Last name"
                  onChange={handleInputChangeProfile}
                  required
                />
                <label>as it appears on your ID*</label>
              </Col>
            </Row>
            <div style={{ marginTop: "15px" }}></div>
            <Row>
              <Col md={4}>
                <label> Email*</label>
              </Col>
              <Col md={8}>
                <FormControl
                  type="email"
                  name="email"
                  value={profile.email}
                  placeholder="email"
                  onChange={handleInputChangeProfile}
                  required
                />
              </Col>
            </Row>
            <div style={{ marginTop: "15px" }}></div>
            <Row>
              <Col md={4}>
                <label> Phone Numer*</label>
              </Col>
              <Col md={8}>
                <FormControl
                  name="phone"
                  placeholder="--- --- -- --"
                  value={profile.phone}
                  onChange={handleInputChangeProfile}
                  required
                />
              </Col>
            </Row>
          </Col>
          <Col md={6}>
            <h3>Address</h3>
            <div style={{ marginTop: "25px" }}></div>
            <Row>
              <Col md={4}>
                <label> Address*</label>
              </Col>
              <Col md={8}>
                <FormControl
                  name="address"
                  value={profile.address}
                  placeholder="Address"
                  onChange={handleInputChangeProfile}
                  required
                />
              </Col>
            </Row>
            <div style={{ marginTop: "15px" }}></div>
            <Row>
              <Col md={4}>
                <label> City*</label>
              </Col>
              <Col md={8}>
                <select
                  className="browser-default custom-select"
                  name="id_city"
                  value={profile.id_city}
                  onChange={handleInputChangeProfile}
                >
                  <option value="0">Select an option</option>
                  {cities.map((city) => (
                    <option key={city.id_city} value={city.id_city}>
                      {city.city}
                    </option>
                  ))}
                </select>
              </Col>
            </Row>
            <div style={{ marginTop: "15px" }}></div>
            <Row>
              <Col md={4}>
                <label> Province*</label>
              </Col>
              <Col md={8}>
                <select
                  className="browser-default custom-select"
                  name="id_state"
                  value={profile.id_state}
                  onChange={handleInputChangeProfile}
                >
                  <option value="0">Select an option</option>
                  {states.map((state) => (
                    <option key={state.id_state} value={state.id_state}>
                      {state.state}
                    </option>
                  ))}
                </select>
              </Col>
            </Row>
            <div style={{ marginTop: "15px" }}></div>
            <Row>
              <Col md={4}>
                <label> Postal Code*</label>
              </Col>
              <Col md={8}>
                <FormControl
                  name="postal_code"
                  value={profile.postal_code}
                  placeholder="Postal Code"
                  onChange={handleInputChangeProfile}
                  required
                />
              </Col>
            </Row>
            <div style={{ marginTop: "15px" }}></div>
            <Row>
              <Col md={4}>
                <label> Country*</label>
              </Col>
              <Col md={8}>
                <select
                  className="browser-default custom-select"
                  name="id_country"
                  value={profile.id_country}
                  onChange={handleInputChangeProfile}
                >
                  <option value="0">Select an option</option>
                  {countries.map((country) => (
                    <option key={country.id_country} value={country.id_country}>
                      {country.country}
                    </option>
                  ))}
                </select>
              </Col>
            </Row>
          </Col>
        </Row>
        <div style={{ marginTop: "45px" }}></div>
      </>
    );
  }

  function getPaidContent() {
    return (
      <>
        <Row>
          <Col md={8}>
            <h3>Direct Deposit Details</h3>
            <div style={{ marginTop: "25px" }}></div>
            <Row>
              <Col md={4}>
                <label> Account Number*</label>
              </Col>
              <Col md={8}>
                <FormControl
                  name="account_number"
                  type="number"
                  value={getPaid.account_number}
                  placeholder=" Account Number"
                  onChange={handleInputChangeGetPid}
                  required
                />
              </Col>
            </Row>
            <div style={{ marginTop: "15px" }}></div>
            <Row>
              <Col md={4}>
                <label> Transit number*</label>
              </Col>
              <Col md={3}>
                <FormControl
                  name="transit_number"
                  type="number"
                  value={getPaid.transit_number}
                  onChange={handleInputChangeGetPid}
                  required
                />
              </Col>
              <Col md={2}>
                <label> SIN number*</label>
              </Col>
              <Col md={3}>
                <FormControl
                  name="sin_number"
                  type="number"
                  value={getPaid.sin_number}
                  onChange={handleInputChangeGetPid}
                  required
                />
              </Col>
            </Row>
            <div style={{ marginTop: "15px" }}></div>
            <Row>
              <Col md={4}>
                <label> Institution number*</label>
              </Col>
              <Col md={3}>
                <FormControl
                  name="institution_number"
                  type="number"
                  value={getPaid.institution_number}
                  onChange={handleInputChangeGetPid}
                  required
                />
              </Col>
              <Col md={2}>
                <label> GST number*</label>
              </Col>
              <Col md={3}>
                <FormControl
                  name="gst_number"
                  type="number"
                  value={getPaid.gst_number}
                  onChange={handleInputChangeGetPid}
                  required
                />
                <label>if applicable</label>
              </Col>
            </Row>
          </Col>
          <Col md={4}>
            <Image
              src={require("../../assets/img/card_example-01.jpg")}
              style={{ width: "100%" }}
              rounded
            />
          </Col>
        </Row>
        <div style={{ marginTop: "45px" }}></div>
      </>
    );
  }

  const handleInputChangeImage1 = (event) => {
    event.preventDefault();
    setNewPhoto1([...newPhoto1, event.target.files[0]]);
  };
  const handleInputChangeImage2 = (event) => {
    event.preventDefault();
    setNewPhoto2([...newPhoto2, event.target.files[0]]);
  };
  const handleInputChangeImage3 = (event) => {
    event.preventDefault();
    setNewPhoto3([...newPhoto3, event.target.files[0]]);
  };

  const onFormSubmitUpdateImage = () => {
    let data = new FormData();
    console.log(newPhoto1);
    data.append("url_front", newPhoto1[0] || verification.url_front);
    data.append("url_back", newPhoto2[0] || verification.url_back);
    data.append("url_face", newPhoto3[0] || verification.url_face);
    data.append("type", verification.type);
    console.log(data);
    api.updateVerification(data, (res) => {});
  };

  function verificationContent() {
    return (
      <>
        <div style={{ marginTop: "45px" }}></div>
        <h3>Identity Verification</h3>
        <div style={{ marginTop: "25px" }}></div>
        <Row>
          <Col md={3}>
            <label>Select ID type*</label>
          </Col>
          <Col md={6}>
            <select
              className="browser-default custom-select"
              name="type"
              value={verification.type}
              onChange={handleInputChangeVerification}
            >
              <option value="">Select an option</option>
              <option value="Drivers license">Drivers license</option>
              <option value="Identity Card">Identity Card</option>
              <option value="Passport">Passport</option>
            </select>
          </Col>
        </Row>
        <div style={{ marginTop: "25px" }}></div>
        <h3>Photos</h3>
        <Row>
          <Col md={4}>
            <div style={{ border: "solid" }}>
              <div style={{ marginTop: "25px" }}></div>
              <FormGroup>
                <FormControl
                  //    style={{width: "100%"}}
                  name="newPhoto1"
                  onChange={handleInputChangeImage1}
                  accept="image/x-png,image/gif,image/jpeg"
                  type="file"
                  id="exampleFormControlFile1"
                  label="Example file input"
                  style={{ width: "50%" }}
                />
              </FormGroup>
              <div style={{ textAlign: "center" }}>
                {verification.url_front === "" ? (
                  <label style={{ margin: "20px" }}>
                    Take or upload a clear photo of the front of your ID. Ensure
                    to capture everything including the edges
                  </label>
                ) : (
                  <>
                    <label>Front</label>{" "}
                    <Image
                      src={
                        "http://jobid.trebolbit.com/" + verification.url_front
                      }
                      style={{ width: "100%" }}
                      rounded
                    />
                  </>
                )}
              </div>

              <div style={{ marginTop: "25px" }}></div>
            </div>
          </Col>
          <Col md={4}>
            <div style={{ border: "solid" }}>
              <div style={{ marginTop: "25px" }}></div>
              <FormGroup>
                <FormControl
                  //    style={{width: "100%"}}
                  name="newPhoto2"
                  accept="image/x-png,image/gif,image/jpeg"
                  onChange={handleInputChangeImage2}
                  type="file"
                  id="exampleFormControlFile1"
                  label="Example file input"
                  style={{ width: "50%" }}
                />
              </FormGroup>
              <div style={{ textAlign: "center" }}>
                {verification.url_back === "" ? (
                  <label style={{ margin: "20px" }}>
                    Take or upload a clear photo of the back of your ID. Ensure
                    to capture everything including the edges
                  </label>
                ) : (
                  <>
                    <label>Back</label>{" "}
                    <Image
                      src={
                        "http://jobid.trebolbit.com/" + verification.url_back
                      }
                      style={{ width: "100%" }}
                      rounded
                    />
                  </>
                )}
              </div>

              <div style={{ marginTop: "25px" }}></div>
            </div>
          </Col>
          <Col md={4}>
            <div style={{ border: "solid" }}>
              <div style={{ marginTop: "25px" }}></div>
              <FormGroup>
                <FormControl
                  //    style={{width: "100%"}}
                  name="newPhoto3"
                  accept="image/x-png,image/gif,image/jpeg"
                  onChange={handleInputChangeImage3}
                  type="file"
                  id="exampleFormControlFile1"
                  label="Example file input"
                  style={{ width: "50%" }}
                />
              </FormGroup>
              <div style={{ textAlign: "center" }}>
                {verification.url_face === "" ? (
                  <label style={{ margin: "20px" }}>
                    Take or upload a clear, well lit photo of your face. Ensure
                    to capture your entire face
                  </label>
                ) : (
                  <>
                    <label>Face</label>{" "}
                    <Image
                      src={
                        "http://jobid.trebolbit.com/" + verification.url_face
                      }
                      style={{ width: "100%" }}
                      rounded
                    />
                  </>
                )}
              </div>

              <div style={{ marginTop: "25px" }}></div>
            </div>
          </Col>
        </Row>
        <div style={{ marginTop: "45px" }}></div>
      </>
    );
  }

  function completedContent() {
    return (
      <>
        <div style={{ textAlign: "center", marginTop: "10%" }}>
          <label style={{ fontSize: "80px", color: "green" }}>
            <AiFillCheckCircle />
          </label>
          <h2>Profile complete!</h2>
        </div>
      </>
    );
  }

  return (
    <>
      <Row style={{ height: "100vh", width: "100%" }}>
        <HeaderBidder />
        <Col sm={10} xs={12} lg={10} md={12} style={{ height: "100%" }}>
          <>
            <div className={classes.root}>
              <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                  const stepProps = {};
                  const labelProps = {};

                  if (isStepSkipped(index)) {
                    stepProps.completed = false;
                  }
                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
              <div>
                {activeStep === steps.length ? (
                  <div>
                    <Typography className={classes.instructions}>
                      All steps completed - you&apos;re finished
                    </Typography>
                    <Button onClick={handleReset} className={classes.button}>
                      Reset
                    </Button>
                  </div>
                ) : (
                  <div>
                    <Typography className={classes.instructions}>
                      {getStepContent(activeStep)}
                    </Typography>
                    <div>
                      <Button
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        className={classes.button}
                      >
                        Back
                      </Button>

                      {activeStep !== steps.length - 1 ? (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleNext}
                          className={classes.button}
                        >
                          Next
                        </Button>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        </Col>
      </Row>
    </>
  );
}
