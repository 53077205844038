import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Grid,
  Form,
  FormControl,
  FormGroup,
  Image,
  Label,
  Button,
} from "react-bootstrap";
import Header from "../header/Header";
import api from "../../services/Api";
import swal from "sweetalert";
import styled from "styled-components";

export default function ServicesDetails(props) {
  const [job, setjob] = useState({});
  const [categories, setCategories] = useState([]);
  const [photos, setPhotos] = useState({});
  const [photo, setPhoto] = useState();
  useEffect(() => {
    //props.match.params.id
    api.getSubCategories((res) => {
      setCategories(res.data);
      getJob();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getJob = () => {
    api.getJobConsult(props.match.params.id, (res) => {
      setjob(res.data[0]);
      setPhotos(res.data[0].job_photoList || []);
      if (res.data[0].job_photoList.length > 0) {
        setPhoto(res.data[0].job_photoList[0].photo_url || "");
      }
    });
  };

  const handleInputChange = (event) => {
    setjob({
      ...job,
      [event.target.name]: event.target.value,
    });
  };

  const onFormSubmitUpdateDataJob = (event) => {
    event.preventDefault();
    job.id_subcategory = parseInt(job.id_subcategory);
    api.updateJob(job, (res) => {
      getJob();
      swal("Good Job", "The job was updated successfully!", "success");
    });
  };

  const deleteJob = (event) => {
    event.preventDefault();
    swal({
      title: "Do you wish to continue ?",
      text: "do you want to delete this job?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        //props.match.params.id
        api.deleteJob(props.match.params.id, (res) => {
          swal("Good Job", "The job was deleted successfully!", "success");
          setTimeout(() => {
            props.history.push("/services");
          }, 3000);
        });
      }
    });
  };
  return (
    <>
      <Row style={{ height: "100vh", width: "100%" }}>
        <Header
          menuActive={{
            dashboard: false,
            users: false,
            services: true,
            payments: false,
          }}
        />
        <Col sm={10} xs={12} lg={10} md={12}>
          <Grid>
            <h1>Job Detail</h1>
            <div style={{ marginTop: "30px" }}></div>
            <Form onSubmit={onFormSubmitUpdateDataJob}>
              <FormGroup>
                <Row>
                  <Col md={3}>
                    <Image
                      src={"http://jobid.trebolbit.com/" + photo}
                      style={{ width: "100%" }}
                      rounded
                    />
                  </Col>
                  <Col md={9}>
                    <Row>
                      <Col md={8}>
                        <Label>Job Title</Label>
                        <FormControl
                          value={job.tittle}
                          name="tittle"
                          placeholder="Job Title"
                          style={{ borderRadius: "12px" }}
                          onChange={handleInputChange}
                          required
                        />
                      </Col>
                    </Row>
                    <div style={{ marginTop: "30px" }}></div>
                    <Row>
                      <Col md="3">
                        <Label>Job Date</Label>
                        <FormControl
                          type="date"
                          value={job.job_date}
                          name="job_date"
                          placeholder="Job Date"
                          style={{ borderRadius: "12px" }}
                          onChange={handleInputChange}
                          required
                        />
                      </Col>
                      <Col md="6">
                        <Label>Job Time</Label>
                        <Row>
                          <Col md="5">
                            <FormControl
                              type="time"
                              value={job.initial_hour}
                              name="initial_hour"
                              placeholder="Initial hour"
                              style={{ borderRadius: "12px" }}
                              onChange={handleInputChange}
                              required
                            />
                          </Col>

                          <Col md="5">
                            <FormControl
                              type="time"
                              value={job.final_hour}
                              name="final_hour"
                              placeholder="Final hour"
                              style={{ borderRadius: "12px" }}
                              onChange={handleInputChange}
                              required
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col md="3">
                        <Label>Category</Label>
                        <select
                          className="browser-default custom-select"
                          name="id_subcategory"
                          value={job.id_subcategory}
                          onChange={handleInputChange}
                        >
                          {categories.map((category) => (
                            <option
                              key={category.id_subcategory}
                              value={category.id_subcategory}
                            >
                              {category.subcategory}
                            </option>
                          ))}
                        </select>
                      </Col>
                    </Row>
                    <div style={{ marginTop: "10px" }}></div>
                    <Row>
                      <Col md="3">
                        <Label>City</Label>
                        <FormControl
                          value={job.city}
                          name="city"
                          disabled
                          placeholder="City"
                          style={{ borderRadius: "12px" }}
                          onChange={handleInputChange}
                          required
                        />
                      </Col>
                      <Col md="3">
                        <Label>State</Label>
                        <FormControl
                          value={job.state}
                          name="state"
                          disabled
                          placeholder="State"
                          style={{ borderRadius: "12px" }}
                          onChange={handleInputChange}
                          required
                        />
                      </Col>
                      <Col md="3">
                        <Label>Postal Code</Label>
                        <FormControl
                          value={job.postal_code}
                          name="postal_code"
                          disabled
                          placeholder="Pstal code"
                          style={{ borderRadius: "12px" }}
                          onChange={handleInputChange}
                          required
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <Label>Description</Label>
                        <FormControl
                          value={job.description}
                          name="description"
                          placeholder="Description"
                          style={{ borderRadius: "12px" }}
                          onChange={handleInputChange}
                          required
                        />
                      </Col>
                    </Row>
                    <div style={{ marginTop: "10px" }}></div>
                    <Row>
                      <Col xs={12} md={12}>
                        <h4>Photos</h4>

                        <div style={{ marginTop: "10px" }}></div>
                        <Row>
                          {Object.keys(photos).map((photo) => (
                            <Col md={3}>
                              {" "}
                              <Image
                                src={
                                  "http://jobid.trebolbit.com/" +
                                  photos[photo].photo_url
                                }
                                style={{ width: "100%" }}
                                rounded
                              />
                            </Col>
                          ))}
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={6}>
                        <StyledButton
                          type="submit"
                          variant="primary"
                          style={{}}
                        >
                          Edit Info
                        </StyledButton>
                      </Col>
                      <Col xs={12} md={6}>
                        <StyledButton
                          type="button"
                          variant="primary"
                          style={{
                            backgroundColor: "tomato",
                          }}
                          onClick={deleteJob}
                        >
                          Delete Job
                        </StyledButton>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </FormGroup>
            </Form>
          </Grid>
        </Col>
      </Row>
    </>
  );
}

const StyledButton = styled(Button)`
  margin-top: 20px;
  border-radius: 12px;
  background-color: #0062cc;
  width: 80%;
  color: white;

  @media (max-width: 600px) {
    width: 100%;
  }
`;
