import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Grid,
  Image,
  Form,
  FormGroup,
  Button,
} from "react-bootstrap";

import Header from "../header/Header";
import api from "../../services/Api";
import swal from "sweetalert";

export default function JobDone(props) {
  const [job, setjob] = useState({});
  const [photos, setPhotos] = useState({});
  useEffect(() => {
    //props.match.params.id
    api.getJobDone(props.match.params.id, (res) => {
      setjob(res.data);
      setPhotos(res.data.photos || []);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  function payNow(e) {
    e.preventDefault();
    swal({
      title: "Do you wish to continue ?",
      text: "please make sure you already have made the transfer!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        //props.match.params.id
        api.getPayNow(props.match.params.id, (res) => {
          swal(res.message, {
            icon: "success",
          });
        });
      }
    });
  }

  return (
    <Row style={{ height: "100vh", width: "100%" }}>
      <Header
        menuActive={{
          dashboard: false,
          users: false,
          services: false,
          payments: true,
        }}
      />
      <Col sm={10} xs={12} lg={10} md={12}>
        <Grid style={{ padding: "2em" }}>
          <h1>Job Done</h1>
          <Row>
            <Col md={6}>
              <Row>
                <Col md={6}>
                  <Image
                    src={"http://jobid.trebolbit.com/" + job.profile_photo}
                    style={{ width: "100%" }}
                    rounded
                  />
                </Col>
                <Col md={6}>
                  <Form>
                    <FormGroup controlId="formBasicEmail">
                      <Row>
                        <h4>First Name</h4>
                      </Row>
                      <Row>
                        <label>{job.first_name + " " + job.last_name}</label>
                      </Row>
                      <div style={{ marginTop: "2px" }}></div>
                      <Row>
                        <h4>Mail</h4>
                      </Row>
                      <Row>
                        <label>{job.email}</label>
                      </Row>
                      <div style={{ marginTop: "2px" }}></div>
                      <Row>
                        <h4>Register Type</h4>
                      </Row>
                      <Row>
                        <label>{job.register_type}</label>
                      </Row>
                      <div style={{ marginTop: "2px" }}></div>
                      <Row>
                        <h4>Register Date</h4>
                      </Row>
                      <Row>
                        <label>{job.create_date}</label>
                      </Row>
                      <div style={{ marginTop: "2px" }}></div>
                      <Row>
                        <h4>Rol</h4>
                      </Row>
                      <Row>
                        <label>{job.role}</label>
                      </Row>
                      <div style={{ marginTop: "2px" }}></div>
                      <Row>
                        <h4>Card</h4>
                      </Row>
                      <Row>
                        <label>{job.account_number}</label>
                      </Row>
                      <div style={{ marginTop: "2px" }}></div>
                      <Row>
                        <h4>Bank</h4>
                      </Row>
                      <Row>
                        <label>{job.institution_number}</label>
                      </Row>
                      <div style={{ marginTop: "2px" }}></div>
                      <Row>
                        <h4>Amount</h4>
                      </Row>
                      <Row>
                        <label>{job.offer}</label>
                      </Row>
                    </FormGroup>
                  </Form>
                </Col>
              </Row>
            </Col>
            <Col md={6}>
              <Row>
                <Col md={3} style={{ textAlign: "center" }}>
                  <Row>
                    <h4>Job date</h4>
                  </Row>
                  <Row>
                    <label>{job.job_date}</label>
                  </Row>
                </Col>
                <Col md={3} style={{ textAlign: "center" }}>
                  <Row>
                    <h4>Job time</h4>
                  </Row>
                  <Row>
                    <label>{job.role}</label>
                  </Row>
                </Col>
                <Col md={3} style={{ textAlign: "center" }}>
                  <Row>
                    <h4>Category</h4>
                  </Row>
                  <Row>
                    <label>{job.subcategory}</label>
                  </Row>
                </Col>
                <Col md={3} style={{ textAlign: "center" }}>
                  <Row>
                    <h4>City</h4>
                  </Row>
                  <Row>
                    <label>{job.city}</label>
                  </Row>
                </Col>
              </Row>
              <div style={{ marginTop: "2px" }}></div>
              <Row>
                <Col md={6} style={{ textAlign: "center" }}>
                  <Row>
                    <h4>Adress</h4>
                  </Row>
                  <Row>
                    <label>{job.addres_line1 + " " + job.addres_line2}</label>
                  </Row>
                </Col>
                <Col md={3} style={{ textAlign: "center" }}>
                  <Row>
                    <h4>Postal code</h4>
                  </Row>
                  <Row>
                    <label>{job.postal_code}</label>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col md={6} style={{ textAlign: "center" }}>
                  <Row>
                    <h4>Description</h4>
                  </Row>
                  <Row>
                    <label>{job.description}</label>
                  </Row>
                </Col>
                <Col md={3} style={{ textAlign: "center" }}>
                  <Row>
                    <h4>Province</h4>
                  </Row>
                  <Row>
                    <label>{job.state}</label>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col md={12} style={{ textAlign: "center" }}>
                  <Row>
                    <h4>Photos</h4>
                  </Row>
                  <Row>
                    {Object.keys(photos).map((photo) => (
                      <Col md={3}>
                        {" "}
                        <Image
                          src={
                            "http://jobid.trebolbit.com/" +
                            photos[photo].photo_url
                          }
                          style={{ width: "100%" }}
                          rounded
                        />
                      </Col>
                    ))}
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <div style={{ textAlign: "center" }}>
            <Button
              variant="primary"
              style={{
                marginTop: "20px",
                borderRadius: "12px",
                backgroundColor: "#0062cc",
                width: "30%",
                color: "white",
              }}
              type="submit"
              onClick={payNow}
            >
              Pay now
            </Button>
          </div>
        </Grid>
      </Col>
    </Row>
  );
}
