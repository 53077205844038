import React, { Component } from "react";
import { Form, Button, Image, Row } from "react-bootstrap";
import api from "../../services/Api";

export default class Confirm extends Component {
  constructor(props) {
    super(props);
    this.state = { email: "", password: "", isError: false, message: "" };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

  onFormSubmitConfirm = (e) => {
    e.preventDefault();
    const query = new URLSearchParams(this.props.location.search);
    this.props.history.push("/");
    const token = query.get("token");
    api.confirmAcount(token, (data) => {
      console.log(data);
      if (data.status !== "error") {
        this.setState({ isError: true });
        this.setState({ message: data.message });
        this.props.history.push("/");
      } else {
        this.setState({ isError: true });
        this.setState({ message: data.message });
      }
    });
  };

  render() {
    return (
      <>
        <Row className="justify-content-md-center" style={{ marginTop: "10%" }}>
          <Image
            src={require("../../assets/img/logo_jobid.png")}
            style={{ width: "10%", height: "10%" }}
            rounded
          />
        </Row>

        <div style={{ marginTop: "2%" }}></div>
        <Row className="justify-content-md-center">
          <h3>Please confirm account </h3>
        </Row>
        <div style={{ marginTop: "2%" }}></div>
        <Row className="justify-content-md-center">
          <Form onSubmit={this.onFormSubmitConfirm} style={{ width: "15%" }}>
            <Button
              variant="primary"
              style={{
                borderRadius: "12px",
                backgroundColor: "#0062cc",
                width: "100%",
                color: "white",
              }}
              type="submit"
            >
              Confirm
            </Button>
          </Form>
        </Row>
      </>
    );
  }
}
