import React, {Component} from "react";
import DataTable from "react-data-table-component";
import {Button} from "react-bootstrap";
import {Link} from "react-router-dom";
import api from "../../services/Api";

const columns = [
   {name: "#", selector: "id_job", ortable: true},
   {name: "Name", selector: "tittle", ortable: true},
   {
      selector: "email",
      name: "Mail",
      sortable: true,
   },
   {
      selector: "subcategory",
      name: "Job",
      sortable: true,
   },
   {
      selector: "finish_date",
      name: "Date",
      sortable: true,
   },
   {
      name: "Status",
      cell: (row) => (!row.finishPay ? "Pending" : "Paid"),
   },
   {
      name: "Status",
      cell: (row) =>
         !row.finishPay ? (
            <Link to={"/payments/" + row.id_job}>
               <Button style={{borderRadius: "12px", backgroundColor: "#0062cc", width: "100%", color: "white"}}>Pay Now</Button>
            </Link>
         ) : (
            ""
         ),
   },
];

export default class PaymentsTable extends Component {
   constructor(props) {
      super(props);
      this.state = {payments: []};
   }
   componentDidMount() {
      api.getPaymentList((res) => {
         this.setState({payments: res.data});
      });
   }

   render() {
      return (
         <>
            <DataTable columns={columns} data={this.state.payments} pagination={true} />
         </>
      );
   }
}
