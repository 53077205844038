import React, { Component } from "react";
import { Col, Grid, Row } from "react-bootstrap";
import Header from "../header/Header";
import ReportsTable from "./ReportsTable";

export default class Reports extends Component {
  render() {
    return (
      <>
        <Row style={{ height: "100vh", width: "100%" }}>
          <Header
            menuActive={{
              dashboard: false,
              users: false,
              services: false,
              payments: true,
            }}
          />

          <Col sm={10} xs={12} lg={10} md={12}>
            <Grid style={{ margin: "5%" }}>
              <h1>Reports</h1>
              <ReportsTable />
            </Grid>
          </Col>
        </Row>
      </>
    );
  }
}
