import React, {Component} from "react";
import DataTable from "react-data-table-component";
import {Button} from "react-bootstrap";
import {Link} from "react-router-dom";
import api from "../../services/Api";

const columns = [
   {name: "#", selector: "id_job", ortable: true},
   {name: "Job Title", selector: "tittle", ortable: true},
   {
      selector: "subcategory",
      name: "Category",
      sortable: true,
   },
   {
      selector: "city",
      name: "City",
      sortable: true,
   },
   {
      selector: "state",
      name: "Province",
      sortable: true,
   },
   {
      selector: "job_date",
      name: "Job Date",
      sortable: true,
   },
   {
      name: "actions",
      cell: (row) => (
         <Link to={"/services/" + row.id_job}>
            <Button style={{borderRadius: "12px", backgroundColor: "#0062cc", width: "100%", color: "white"}}>Edit</Button>
         </Link>
      ),
   },
];

export default class ServicesTable extends Component {
   constructor(props) {
      super(props);
      this.state = {services: [], categories: [], id_category: 1};
      this.handleChange = this.handleChange.bind(this);
   }
   componentDidMount() {
      api.getCategories((res) => {
         this.setState({categories: res.data});
      });
      api.getJobsList(parseInt(this.state.id_category), (res) => {
         this.setState({services: res.data});
      });
   }

   handleChange(event) {
      const {name, value} = event.target;
      this.setState({[name]: value});
      api.getJobsList(value, (res) => {
         this.setState({services: res.data});
      });
   }

   render() {
      return (
         <>
            <select
               className="browser-default custom-select"
               name="id_category"
               value={this.state.id_category}
               onChange={this.handleChange}
            >
               {this.state.categories.map((category) => (
                  <option key={category.id_category} value={category.id_category}>
                     {category.category}
                  </option>
               ))}
            </select>

            <DataTable columns={columns} data={this.state.services} pagination={true} />
         </>
      );
   }
}
