import React from "react";
import {Route, Redirect} from "react-router-dom";
import auth from "./helpers/Auth";

export const ProtectedRoute = ({component: Component, ...rest}) => {
   return (
      <Route
         {...rest}
         render={(props) => {
            if (auth.isAuthenticated() && auth.getUserType() === "1") {
               return <Component {...props} />;
            } else {
               return (
                  <Redirect
                     to={{
                        pathname: "/",
                        state: {from: props.location},
                     }}
                  />
               );
            }
         }}
      />
   );
};

export const ProtectedRouteNoAdmin = ({component: Component, ...rest}) => {
   return (
      <Route
         {...rest}
         render={(props) => {
            if (auth.isAuthenticated() && (auth.getUserType() === "2" || auth.getUserType() === "1")) {
               return <Component {...props} />;
            } else {
               return (
                  <Redirect
                     to={{
                        pathname: "/",
                        state: {from: props.location},
                     }}
                  />
               );
            }
         }}
      />
   );
};
